var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userManager.checkRole(['kd', 'htkd', 'xnk', 'cs']))?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","show-select":"","server-items-length":_vm.dataCount,"sort-by":"created_at","sort-desc":"","options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 50, 100, 200],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Khai báo")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.initialize($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.uploadFile}}),(
            _vm.userManager.checkRole('xnk') &&
            _vm.item &&
            (_vm.item.status === 'dk' || _vm.item.status === 'x')
          )?_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"color":"primary"},on:{"click":_vm.openFile}},[_vm._v(" Tải file "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-calendar-import")])],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-form',{model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Số lượng khai","type":"number"},model:{value:(_vm.editedItem.export_count),callback:function ($$v) {_vm.$set(_vm.editedItem, "export_count", $$v)},expression:"editedItem.export_count"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Thuế VAT khai","type":"number"},model:{value:(_vm.editedItem.export_bill_vat),callback:function ($$v) {_vm.$set(_vm.editedItem, "export_bill_vat", $$v)},expression:"editedItem.export_bill_vat"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Thuế XNK khai","type":"number"},model:{value:(_vm.editedItem.export_import_tax),callback:function ($$v) {_vm.$set(_vm.editedItem, "export_import_tax", $$v)},expression:"editedItem.export_import_tax"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("CANCEL"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.form},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("SAVE"))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.userManager.checkRole(['xnk']))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("EDIT"))+" ")]):_vm._e(),(_vm.userManager.checkRole(['kd', 'htkd']))?[(!item.tax_checked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.approveItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_vm._v(" Xác nhận ")]):_vm._e()]:_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("NO_DATA"))+" ")])]},proxy:true},{key:"item.export_bill_vat",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.export_bill_vat))+" ")]}},{key:"item.export_import_tax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.export_import_tax))+" ")]}},{key:"item.export_taxed_per_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("money")(item.export_taxed_per_price))+" ")]}},{key:"item.tax_checked",fn:function(ref){
          var item = ref.item;
return [(item.tax_checked)?_c('b',[_vm._v("OK")]):_vm._e()]}},{key:"item.export_description",fn:function(ref){
          var item = ref.item;
return [_c('read-more',{attrs:{"text":item.export_description,"max-chars":50,"more-str":"đọc thêm","less-str":"ẩn bớt","link":"#"}})]}},{key:"item.product_description",fn:function(ref){
          var item = ref.item;
return [_c('read-more',{attrs:{"text":item.product_description,"max-chars":50,"more-str":"đọc thêm","less-str":"ẩn bớt","link":"#"}})]}}],null,false,3946178351),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }