







































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import util from "@/services/util";

export default Vue.extend({
  data() {
    return {
      util,
      userManager,
      dialog: false,
      form: false,
      ruleRequired: value => !!value || this.$t("REQUIRED"),
      headers: [
        { text: "STT tờ khai", value: "export_register_index" },
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: "Số lượng", value: "product_count" },
        { text: "Số lượng khai", value: "export_count" },
        { text: "Đơn vị", value: "product_unit" },
        { text: "Đơn vị khai", value: "export_unit" },
        { text: "Thông tin SP", value: "product_description" },
        { text: "Thông tin khai", value: "export_description" },
        { text: "Giá so sánh", value: "product_compared_price" },
        { text: "VAT khai", value: "export_bill_vat" },
        { text: "Thuế NK khai", value: "export_import_tax" },
        { text: "Đơn giá tính thuế", value: "export_taxed_per_price" },
        { text: "Check thuế", value: "tax_checked" },
        !userManager.getImpersonateUserInfo() && {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false
        }
      ],
      item: {},
      options: {},
      search: "",
      loading: false,
      data: [],
      dataCount: 0,
      selectedItems: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {}
    };
  },
  computed: {
    formTitle() {
      return this.$t("EDIT");
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true
    }
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.item = await apiClient.truckGet(this.$route.params.id);
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters: [
          {
            key: "package_id",
            operator: "match",
            value: this.search
          },
          {
            key: "truck_id",
            operator: "equal_to",
            value: this.$route.params.id
          }
        ]
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      this.editedItem.tax_checked = false;
      if (
        await apiClient.packageUpdate(this.editedItem, [
          "export_count",
          "export_bill_vat",
          "export_import_tax",
          "tax_checked"
        ])
      ) {
        this.close();
        await this.initialize();
      }
    },
    async approveItem(item) {
      if (
        await apiClient.packageUpdate({
          id: item.id,
          tax_checked: true
        })
      ) {
        await this.initialize();
      }
    },
    openFile() {
      const f = this.$refs.fileInput;
      f.click();
    },
    async uploadFile() {
      const file = this.$refs.fileInput.files[0];
      if (!file) {
        return;
      }
      const result = await apiClient.truckImportExportInfo(this.item.id, file);
      if (result) {
        await this.initialize();
      }
    }
  }
});
